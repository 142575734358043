<template>
  <div class="content-container">
    <PageTitle :title="classData.name || ' '" :sub-title="classData.schoolName || ' '" class="page-title">
      <template #extra-content>
        <!-- TO DO: add proper icon to insight button -->
        <BasicButton
          button-class="pink-button insight-button"
          :button-text="$t('CLASSES_HELPDESK.INSIGHT')"
          :icon="require('../../../assets/icons/insights_black_24dp.svg')"
          @click="viewInsights()"/>
      </template>
    </PageTitle>

    <h1 class="page-section-title section-title">{{ $t('CLASS_DETAILS') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')" :route-name="ROUTE_NAMES_HELPDESK.CLASSES_OVERVIEW"/>
    <!-- Class detail fields -->
    <div class="class-details-box">
      <UserDataField :field-title="$t('NAME')" :user-data="classData.name || ' '" class="data-item"/>
      <UserDataField :field-title="$t('TOTAL_STUDENTS')" :user-data="classData.studentCount || ' '" class="half-width"/>

      <div class="edit-details">
        <BasicButton :button-text="$t('EDIT_DATA')" @click="editClass()"/>
      </div>
    </div>

    <!-- Linked Teachers -->
    <h1 class="page-section-title section-title">{{ $t('LINKED_TEACHERS') }}</h1>
    <CustomTable v-if="linkedTeachers.length > 0"
                 :options="linkedTeachersOptions"
                 :table-data="linkedTeachers"
                 :less-padding="true"
                 table-class="schools-table linked-classes"
                 class="class-details-table">
      <template #name="props">
        <div>{{ props.rowData.name }}</div>
      </template>
      <template #group="props">
        <div>{{ props.rowData.group }}</div>
      </template>
      <template #totalStudents="props">
        <div>{{ props.rowData.totalStudents }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <!-- TODO change action buttons with correct images. -->
          <ActionButton :text="$t('CLASSES_HELPDESK.UNLINK')"
                        :icon="require('../../../assets/icons/ic_remove_person.svg')"
                        custom-class="button-red"
                        class="action"
                        @click="unlinkTeacher(props.rowData)"/>
          <ActionButton :text="$t('VIEW')"
                        :icon="require('../../../assets/icons/icn_visibility.svg')"
                        custom-class="button-blue"
                        class="action"
                        @click="viewTeacher(props.rowData)"/>
        </div>
      </template>
    </CustomTable>

    <!-- Other Teachers -->
    <h1 class="page-section-title section-title other-teachers-title">{{ $t('OTHER_TEACHERS') }}</h1>
    <CustomTable v-if="otherTeachers.length > 0"
                 :options="otherTeachersOptions"
                 :table-data="otherTeachers"
                 :less-padding="true"

                 table-class="schools-table linked-classes"
                 class="class-details-table">
      <template #name="props">
        <div>{{ props.rowData.name }}</div>
      </template>
      <template #group="props">
        <div>{{ props.rowData.group }}</div>
      </template>
      <template #totalStudents="props">
        <div>{{ props.rowData.totalStudents }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="$t('CLASSES_HELPDESK.LINK')"
                        :icon="require('../../../assets/icons/ic_add_person.svg')"
                        custom-class="button-green"
                        class="action"
                        @click="linkTeacher(props.rowData)"/>
          <ActionButton :text="$t('VIEW')"
                        :icon="require('../../../assets/icons/icn_visibility.svg')"
                        custom-class="button-blue"
                        class="action"
                        @click="viewTeacher(props.rowData)"/>
        </div>
      </template>
    </CustomTable>

    <!-- Delete Class Warning -->
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { computed } from '@vue/reactivity'
import { ROUTE_NAMES_HELPDESK } from '@/router/modules/helpdesk'
import BackButton from '@/components/elements/BackButton'
import UserDataField from '@/components/elements/settings/UserDataField'
import CustomTable from '@/components/table/CustomTable'
import { getTableOptions, LINKED_TEACHERS } from '@/utils/helpers/customTable/CustomTableHelper'
import ActionButton from '@/components/table/ActionButton'
import { GET_CLASS_DETAILS } from '@/store/modules/helpdesk/actions'
import ApiHandler from '../../../utils/handlers/ApiHandler'
import BasicButton from '@/components/elements/basic/BasicButton'

export default {
  name: 'ClassDetails',
  components: {
    ActionButton,
    CustomTable,
    UserDataField,
    BackButton,
    PageTitle,
    BasicButton
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const classId = route.params.classId

    const classData = computed(() => store.getters.getClassDetails ?? {})

    getDetails()

    function getDetails() {
      store.dispatch(GET_CLASS_DETAILS, classId)
    }

    function viewTeacher(teacher) {
      router.push({ name: ROUTE_NAMES_HELPDESK.TEACHER_DETAILS, params: { teacherId: teacher.id } })
    }

    function viewInsights() {
      router.push({ name: ROUTE_NAMES_HELPDESK.CLASSES_OVERVIEW_INSIGHT, params: { classId: classId } })
    }

    /** Linked Teachers **/
    const linkedTeachers = computed(() => {
      if (!classData.value.linkedTeachers) {
        return []
      }
      return classData.value.linkedTeachers
    })

    function linkTeacher(rowData) {
      const formData = new FormData()
      formData.append('teacherId', rowData.id)
      formData.append('schoolClassId', classId)
      ApiHandler.post('helpdesk/teacher/link-teacher-to-class', formData).then(() => getDetails())
    }

    /** Other Teachers **/
    const otherTeachers = computed(() => {
      if (!classData.value.otherTeachers) {
        return []
      }
      return classData.value.otherTeachers
    })

    function editClass() {
      router.push({ name: ROUTE_NAMES_HELPDESK.CLASS_EDIT, params: { schoolId: classId } })
    }

    function unlinkTeacher(rowData) {
      const formData = new FormData()
      formData.append('teacherId', rowData.id)
      formData.append('schoolClassId', classId)
      ApiHandler.post('helpdesk/teacher/unlink-teacher-to-class', formData).then(() => getDetails())
    }

    return {
      ROUTE_NAMES_HELPDESK,
      classId,
      classData,
      viewTeacher,
      viewInsights,
      editClass,

      /** Linked Teachers **/
      linkedTeachers,
      linkTeacher,

      /** Other Teachers **/
      otherTeachers,
      unlinkTeacher,

      /** Tables **/
      otherTeachersOptions: getTableOptions(LINKED_TEACHERS),
      linkedTeachersOptions: getTableOptions(LINKED_TEACHERS),
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.class-details-box {
  margin-top: rem(24);
  width: rem(746);

  .data-item {
    &:not(:last-of-type) {
      margin-bottom: rem(16);
    }
  }

  .edit-details {
    display: flex;
    margin-bottom: rem(56);
    margin-top: rem(40);
  }

  .half-width {
    width: 50%;
  }
}

.other-teachers-title {
  margin-top: rem(40);
}

/** Delete button **/
.delete-button {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: rem(10) rem(21);

  .icon {
    margin-right: rem(15);
    width: rem(15);
  }
}
/** **/
</style>
